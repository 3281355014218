/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<i class="icon-home"></i>
```
*/
#glyph-bookmark {
  background-image: url(/static/images/glyphs.png);
  background-position: 0px -38px;
  width: 21px;
  height: 21px;
}
#glyph-dateArrow {
  background-image: url(/static/images/glyphs.png);
  background-position: -46px -27px;
  width: 11px;
  height: 6px;
}
#glyph-downArrow {
  background-image: url(/static/images/glyphs.png);
  background-position: -73px -43px;
  width: 16px;
  height: 10px;
}
#glyph-next {
  background-image: url(/static/images/glyphs.png);
  background-position: -23px 0px;
  width: 21px;
  height: 36px;
}
#glyph-pagiNext {
  background-image: url(/static/images/glyphs.png);
  background-position: 0px -61px;
  width: 8px;
  height: 11px;
}
#glyph-pagiPrev {
  background-image: url(/static/images/glyphs.png);
  background-position: -10px -61px;
  width: 8px;
  height: 11px;
}
#glyph-pinkArrow {
  background-image: url(/static/images/glyphs.png);
  background-position: -46px 0px;
  width: 25px;
  height: 25px;
}
#glyph-prev {
  background-image: url(/static/images/glyphs.png);
  background-position: 0px 0px;
  width: 21px;
  height: 36px;
}
#glyph-rss {
  background-image: url(/static/images/glyphs.png);
  background-position: -73px 0px;
  width: 20px;
  height: 20px;
}
#glyph-search {
  background-image: url(/static/images/glyphs.png);
  background-position: -73px -22px;
  width: 20px;
  height: 19px;
}
#glyph-twitter {
  background-image: url(/static/images/glyphs.png);
  background-position: -46px -38px;
  width: 22px;
  height: 19px;
}
#glyph-whiteArrow {
  background-image: url(/static/images/glyphs.png);
  background-position: -23px -38px;
  width: 21px;
  height: 21px;
}
header,section,footer,aside,nav,main,article,figure{display:block}
body{margin:0px; padding:0px; font-family:'Roboto', sans-serif; font-weight:400;}
ol,ul{list-style:none}
blockquote,q{quotes:none}
img{border:none; margin:0px; padding:0px; max-width:100%; display:block;}
textarea{outline:none;}
blockquote:before,blockquote:after,q:before,q:after{content:none}
table{border-collapse:collapse;border-spacing:0}
a{text-decoration:none; outline:none;}
h1,h2,h3,h4,h5,h6,ul,p,form,input{margin:0px; padding:0px; outline:none; font-weight:normal;}
.clear:before,.clear:after{content:" "; display:table;}
.clear:after{clear:both;}
.clear{*zoom:1;}
input[type="submit"]{-webkit-appearance:none;}
a, nav li a:after, .sComment, .topLinks{-webkit-transition: all 200ms ease-in; -moz-transition: all 200ms ease-in; -ms-transition: all 200ms ease-in; -o-transition: all 200ms ease-in;transition: all 200ms ease-in;}
.none{-webkit-transition:none; -moz-transition:none; -ms-transition:none; -o-transition:none; transition:none;}
select{-webkit-appearance:none; -moz-appearance: none; text-indent: 1px; text-overflow: '';}
*{box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;}
::-webkit-input-placeholder{color:#7d7d7d; opacity:1;}
::-moz-placeholder{color:#7d7d7d; opacity:1;}
:-ms-input-placeholder{color:#7d7d7d; opacity:1;}
:-moz-placeholder{color:#7d7d7d; opacity:1;}
.alert{color:#a84444;background-color:#f2dddd;border-color:#ebcccc;border-radius:4px;padding:4px;}
ul.errorlist{color:red;}



/* Pure CSS top menu */

/* Better box-model */
*,
*:before,
*:after {
    box-sizing: border-box;
}

nav li {
    width: 100%;
}

/*
    Screen reader only
*/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

/* Top menu base style - mobile first (see media.css for larger devices) */

/* Hamburger button styling */
.menu-toggle {
    display: inline-block;
    padding: 4px 4px 6px 4px;
    line-height: 1em;
    font-size: 1em;
    background-color: #ff055b;
    cursor: pointer;
}

.menu-toggle:hover,
.menu-toggle:focus {
    color: #c00;
}

/* Hamburger dropdown menu styling */
.main-menu {
    display: none;
    position: absolute;
    /* Push menu to the left for future repositioning */
    left: -200px;
    transition: left 0.3s ease,
                box-shadow 0.3s ease;
    z-index: 999;
}

.main-menu ul {
    background: #1a1a1a;
    display: inline-block;
    -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
    -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
    list-style: none;
    box-shadow: -8px 0 8px rgba(0,0,0,.5);
    width: 200px;
}

/* Hamburger dropdown menu links */
.main-menu a {
    float: none;
    padding: 11px 11px;
    margin: 0;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #000;
    text-decoration: none;
    height: auto;
    line-height: 15px;
    font-size: 15px;
    white-space: nowrap;
}

/* Hamburger dropdown close button */
.main-menu a.menu-close {
    border-bottom: 0;
}

.main-menu .menu-close {
    right: 0;
    top: 0;
}

/* Hamburger menu click response */
.main-menu:target,
.main-menu[aria-expanded="true"] {
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
    display: block;
    left: auto;
    right: 0;
    outline: none;
    margin-right: 13px;
}

/* Close hamburger dropdown menu button */
.main-menu:target .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
    background-color: #ff055b;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 1em;
    text-align: right;
    width: 200px;
    z-index: 1001;
}

/* Hamburger dropdown menu list */
.main-menu:target ul,
.main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
}

/* Hamburger dropdown menu backdrop */
.main-menu:target + .backdrop,
.main-menu[aria-expanded="true"] + .backdrop{
    background: #000;
    background: rgba(0,0,0,.85);
    cursor: default;
    display: block;
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
}

/* Make the hamburger menu backdrop fixed, if supported */
@supports (position: fixed) {
    /*
        Allow backdrop to stay fixed and not "scroll"
    */
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop {
        position: fixed;
    }
}

/* Style for the individual bars in the hamburger button */
.hamburger-bar {
    background-color: #fff;
    display: block;
    margin-top: 3px;
    transition: 0.4s;
    height: 3px;
    width: 22px;
}


/* Styling for sprites/glyph to replace separate images */

i{display: inline-flex;}
#glyph-rss{margin: 10px 11px 10px 11px;}
#glyph-twitter{margin: 10px;}

.fSiteInfo #glyph-whiteArrow {vertical-align: text-top;}
.fSiteInfoN i {vertical-align: middle;}
.rateDis i {vertical-align: middle;}
.reviewPDiscountInfo i {vertical-align: middle;}
.sortBy i {position: relative;right: 20px;top: -10px;}
.topLinks #glyph-whiteArrow {vertical-align: middle;}

/* Main site styling */

.centerwrap{width:1174px; margin:auto;}
header{background-color:#222222; position:fixed; width:100%; left:0px; top:0px; z-index:10}
.logo{float:left; margin:1px 0px;}
.hRight{float:right; padding-top:10px;}
nav{float:left; margin-right:30px;}
nav li{float:left; font-family:'Lato', sans-serif; font-weight:400; text-transform:uppercase; margin-right:22px; letter-spacing:-0.01em; position:relative; font-size:15px;}
nav li:last-child{margin-right:0px;}
nav li a{color:#fff; line-height:37px; display:block;}
nav li a.active{font-weight:700; color:#ff055b;}
nav li a:hover{color:#ff055b;}
nav li a:after{background-color:#ff055b; height:4px; left:51%; right:50%; content:''; position:absolute; bottom:-10px;}
nav li a:hover:after, nav li a.active:after{left:0px; right:0px;}
.searchBlock{float:left;}
.searchArea{float:left; position:relative; width:180px;}
.searchbox{width:100%; background-color:#fff; border:1px solid #dddddd; height:37px; border-radius:5px; -webkit-border-radius:5px; color:#9d9c9c; padding-left:12px; padding-right:40px; font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em;}
.searchIcon{width:20px; height:19px; background:#fff url(/static/images/glyphs.png) -73px -22px; cursor:pointer; border:none; margin: 8px 10px 8px 10px; position:absolute; right:1px; top:1px;} /* Formerly images/search.png */
.bookmark{font-family:'Lato', sans-serif; font-weight:400; line-height:12px; font-size:12px; letter-spacing:-0.01em; text-transform:uppercase; float:left; margin-left:16px; margin-top:6px;}
.bookmark a{background:url(/static/images/glyphs.png) 0px -38px/21px 21px no-repeat left center; color:#fff; padding-left:26px; display:block} /* Formerly /images/bookmark.png */
.bookmark a:hover{color:#ff055b;}
.item{display:flex; justify-content:space-between;}
.fSitePic{float:left; width:max-content; padding:7px; background-color:#fff;}
.fSiteDetails{float:right; width:calc(100% - 230px); display:flex; justify-content:space-between; flex-direction:column;}
.fSiteTitle{background-color:#fff; line-height:48px;}
.fSiteTitle h2{float:left; color:#302f2f; font-size:25px; font-family:'Roboto', sans-serif; font-weight:700; padding-left:14px;}
.fSiteTitle h2 span{color:#ff055b;}
.rating{float:right; background-color:#ff055b; text-align:center; width:90px; font-family:'Roboto', sans-serif; font-weight:500; color:#fff; font-size:20px; letter-spacing:-0.01em;}
.fSiteContent{padding-bottom:10px;}
.fSiteContent p{font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em; line-height:21px; color:#7d7c7c;}
a.review{font-size:16px; color:#ff055b; margin-top:2px; display:inline-block;}
a.review span{text-decoration:underline;}
a:hover.review span{text-decoration:none;}
.fSiteInfo{ display:flex; justify-content:space-between;}
.trmc{float:left; width:378px;}
.trmc li{float:left; width:187px!important; font-family:'Roboto', sans-serif; font-weight:700; line-height:29px; margin-right:4px; margin-bottom:5px; background-color:#ffffff; color:#258017; padding:0 10px; font-size:15px;}
.trmc li a{color:#258017}
.trmc li span{color:#222; font-weight:400;}
.trmc li:nth-child(2n){margin-right:0px;}
.trmc li + li + li{margin-bottom:0px;}
.discount{float:left; width:196px; text-align:center; font-family:'Roboto', sans-serif; font-weight:400; font-size:22px; color:#258017; line-height:26px;}
.discount span{display:block; font-size:34px; font-weight:700; line-height:34px;}
a.viewDiscountF{float:left; background-color:#258017; font-family:'Roboto', sans-serif; font-weight:700; border-radius:3px; -webkit-border-radius:3px; line-height:63px; text-transform:uppercase; font-size:19px; color:#fff; padding:0 36px; max-height:63px;}
a.viewDiscountF span{display:inline-block; max-height:63px;} /* Formerly /images/whiteArrow.png */
a:hover.viewDiscountF{background-color:#3aad28; color:#444;}
a.visitSite{float:left; background-color:#222222; font-family:'Roboto', sans-serif; font-weight:700; border-radius:3px; -webkit-border-radius:3px; line-height:63px; text-transform:uppercase; font-size:29px; color:#fff; padding:0 36px; max-height:63px;}
a.visitSite span{display:block; max-height:63px;} /* Formerly /images/pinkArrow.png */
a:hover.visitSite{background-color:#fff; color:#222;}
.customNavigation{position:absolute; left:0px; top:50%; transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); width:100%; height:36px;}
.prevSlide{background:url(/static/images/glyphs.png) 0px 0px; width:21px; height:36px; text-indent:-9999px; display:block; position:absolute; left:20px; cursor:pointer;} /* Formerly /images/prev.png */
.nextSlide{background:url(/static/images/glyphs.png) -23px 0px; width:21px; height:36px; text-indent:-9999px; display:block; position:absolute; right:20px; cursor:pointer;} /* Formerly /images/next.png */
.owl-carousel{z-index:2;}
aside{float:left; width:165px;}
aside h1{font-family:'Roboto', sans-serif; font-weight:700; font-size:14px; background-color:#ff055b; line-height:30px; padding:0 8px; color:#fff; text-transform:uppercase; margin-bottom:5px;}
.topSites {margin-bottom:36px; font-family:'Roboto', sans-serif; font-weight:400; font-size:12px; border-bottom:1px solid #dbdbdb; text-transform:uppercase; text-align: -webkit-match-parent;}
.topSites a{color:#232323; line-height:23px; display:block;}
.topSites a span{float:right; color:#ff055b;}
.topSites a:hover{background-color:#f1f1f1; padding:0 8px;}
.topniches{margin-bottom:36px; font-family:'Roboto', sans-serif; font-weight:400; font-size:12px; border-bottom:1px solid #dbdbdb; text-transform:uppercase; text-align: -webkit-match-parent;}
.topniches a{color:#232323; line-height:23px; display:block;}
.topniches a span{float:right; color:#ff055b;}
.topniches a:hover{background-color:#f1f1f1; padding:0 8px;}
.listNiches{margin-bottom:36px; font-family:'Roboto', sans-serif; font-weight:400; font-size:12px; border-bottom:1px solid #dbdbdb; text-transform:uppercase; text-align: -webkit-match-parent;}
.listNiches a{color:#232323; line-height:23px; display:block;}
.listNiches a span{float:right; color:#ff055b;}
.listNiches a:hover{background-color:#f1f1f1; padding:0 8px;}
.bodyRight{float:right; width:994px;}
h1.title{font-family:'Roboto', sans-serif; font-weight:700; font-size:25px; letter-spacing:-0.025em; background-color:#222222; line-height:53px; padding-left:18px; margin-bottom:14px; color:#fff;}
.siteReview{float:left; border:2px solid #d8d8d8; padding:15px 12px; width:490px; margin-right:14px; margin-bottom:18px;}
.siteReview:nth-child(2n){margin-right:0px;}
.siteReviewPic{float:left;}
.siteReviewD{float:right; width:248px; height:230px;}
.siteReviewD h2{font-family:'Roboto', sans-serif; font-weight:500; font-size:24px; letter-spacing:-0.025em;}
.siteReviewD h2 a{color:#302f2f;}
.siteReviewD h2 a:hover{color:#ff055b;}
.lastUpdates{font-family:'Roboto', sans-serif; font-weight:400; font-size:16px; color:#7d7c7c; border-bottom:1px solid #3d3b3b; padding-bottom:10px; letter-spacing:-0.01em; line-height:20px;}
.lastUpdates span{color:#258017; text-decoration:line-through;}
.lastUpdates strong{color:#258017; font-weight:700;}
.expand{max-height:100%!important}
.siteReviewC{padding-top:8px;}
.siteReviewC p{font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; max-height:114px; overflow:hidden; text-overflow:ellipsis; word-wrap: break-word; line-height:19px; color:#232323; letter-spacing:-0.01em;}
.fullReview{font-family:'Roboto', sans-serif; font-weight:500; font-size:15px; letter-spacing:-0.01em; color:#222222;}
.fullReview a{color:#222222;}
.fullReview a span{text-decoration:underline;}
.fullReview a:hover span{text-decoration:none;}
.fullReview a:hover{color:#ff055b;}
.rateDis{display:flex; clear:both; padding-top:15px; justify-content:space-between;}
.siteRating{line-height:50px; background-color:#ff055b; font-family:'Roboto', sans-serif; font-weight:500; font-size:20px; letter-spacing:-0.01em; width:90px; text-align:center; color:#fff;}
a.visitSiteR{background-color:#222222; font-family:'Roboto', sans-serif; font-weight:700; line-height:50px; text-transform:uppercase; font-size:25px; color:#fff; width:248px; text-align:center; border-radius:3px; -webkit-border-radius:3px;}
a.visitSiteR span{display:inline-block;}
a:hover.visitSiteR{background-color:#444;}
a.viewDiscount{background-color:#258017; font-family:'Roboto', sans-serif; font-weight:700; line-height:50px; text-transform:uppercase; font-size:25px; color:#fff; width:248px; text-align:center; border-radius:3px; -webkit-border-radius:3px;}
a.viewDiscount span{display:inline-block;} /* Formerly /images/whiteArrow.png */
a:hover.viewDiscount{background-color:#3aad28;}
.siteDiscount{width:120px; text-align:center; font-family:'Roboto', sans-serif; font-weight:400; font-size:13px; color:#258017; line-height:16px; padding-top:5px;}
.siteDiscount span{display:block; font-size:20px; font-weight:700; line-height:24px;}
.siteDiscount span a{color:#258017;}
.siteDiscount span a:hover{color:#222; text-decoration:underline;}
.viewMore{text-align:center; padding-top:36px; padding-bottom:58px;}
.viewMore a{background-color:#ff055b; font-family:'Roboto', sans-serif; font-weight:700; line-height:58px; text-transform:uppercase; font-size:25px; color:#fff; text-align:center; border-radius:3px; -webkit-border-radius:3px; padding:0 40px; display:inline-block}
.viewMore a span{display:inline-block;} /* Formerly /images/whiteArrow.png */
.viewMore a:hover{background-color:#222222;}
.discountPorn {background-color: #f1f1f1; padding-bottom: 18px;}
.discountPorn h1 {margin-bottom:0px;}
.discountPorn div {display:flex; text-align:center; padding-left: 8px; padding-right: 8px;}
.discountPorn div div {display: block; margin-bottom:6px; margin-right:6px; background-color:#fff; line-height:30px; font-size:15px; color:#222222; font-family:'Roboto', sans-serif;}
.discountPorn .pornTitle {padding-left: 18px; padding-top: 18px;}
.discountPorn .pornTitle div{font-weight:500; color:#464646; background-color:#dad9d9; text-transform:uppercase; display: block;}
.discountPorn .content .siteName, .discountPorn .pornTitle .siteName {padding:0 14px; text-align:left;}
.discountPorn .content .dPrice{color:#258017; font-weight:700;}
.discountPorn .content .niche{padding:0 14px; text-align:left;}
.discountPorn .content {padding-left: 18px;}

.siteName a{color:#ff055b; text-decoration:underline;}
.siteName a:hover{text-decoration:none; color:#222;}
.siteName{width:292px;}
.dPrice{width:200px;}
.rPrice{width:200px;}
.siteRatingB{width:200px;}
.reviewsSoon{margin-bottom:110px;}
.reviewsSoon h1{margin-bottom:10px;}
.reviewsSoon a{float:left; width:244px; margin-right:5px; margin-bottom:5px; font-weight:400; font-size:15px; line-height:30px; padding:0 12px; background-color:#f1f1f1; display:block; color:#222; text-decoration:underline;}
.reviewsSoon a:nth-child(4n+1){margin-right:0px;}
.reviewsSoon a:hover{text-decoration:none; color:#ff055b;}
footer{background-color:#222222;}
.fLogo{float:left; padding-top:5px; padding-bottom:12px;}
.footerRight{float:right; padding-top:16px;}
.social{float:right;}
.social a{float:left; width:43px; height:40px; background-color:#404040; border-radius:3px; -webkit-border-radius:3px; margin-right:10px;}
.social a:last-child{margin-right:0px;}
.social a:hover{background-color:#ff055b;}
.flinks{float:left; margin-right:16px;}
.topRated{font-size:15px; font-family:'Lato', sans-serif; font-weight:400; padding-bottom:6px;}
.topRated a{color:#fff; display:inline-block; text-transform:uppercase; margin-right:28px;}
.topRated a:last-child{margin-right:0px;}
.topRated a:hover{color:#ff055b;}
.flinks p{font-size:12px; font-family:'Lato', sans-serif; font-weight:400; color:#afaeae; letter-spacing:-0.01em; text-align:right;}
.flinks p a{color:#afaeae;}
.flinks p a:hover{color:#ff055b;}
.flinks p span{color:#fff; padding:0 2px;}

/* Niche */
.innerBody{padding-top:90px;}
.featuredSite{display:flex; justify-content:space-between; margin-bottom:24px; background-color:#f1f1f1; padding:24px;}
.fSitePicN{float:left; background-color:#fff;}
.fSiteDetailsN{float:right; width:calc(100% - 230px); display:flex; justify-content:space-between; flex-direction:column;}
.fSiteTitleN{background-color:#fff; line-height:48px; padding:0 14px;}
.fSiteTitleN h2{color:#302f2f; font-size:25px; font-family:'Roboto', sans-serif; font-weight:700;}
.fSiteTitleN h2 span, .fSiteTitleN h2 a{color:#ff055b;}
.fSiteTitleN h2 a:hover{color:#222;}
.ratingN{background-color:#ff055b; text-align:center; width:90px; font-family:'Roboto', sans-serif; font-weight:500; color:#fff; font-size:20px; letter-spacing:-0.01em; line-height:54px; float:left;}
.fSiteContentN{padding-bottom:10px;}
.fSiteContentN p{font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em; line-height:21px; color:#7d7c7c;}
a.reviewN{font-size:16px; color:#ff055b; margin-top:2px; display:inline-block; float:right;}
a.reviewN span{text-decoration:underline;}
a:hover.reviewN span{text-decoration:none;}
.discountN{text-align:center; font-family:'Roboto', sans-serif; font-weight:400; font-size:21px; color:#258017; line-height:22px; float:left; margin-left:25px;}
.discountN span{display:block; font-size:32px; font-weight:700; line-height:30px;}
.discountN a{color:#258017;}
.discountN a:hover{color:#222; text-decoration:underline;}
.lastUp{font-family:'Roboto', sans-serif; font-weight:400; padding-top:5px; font-size:16px; color:#7d7c7c; border-bottom:1px solid #3d3b3b; padding-bottom:5px; letter-spacing:-0.01em;}
.lastUp br{display:none;}
.lastUp span{color:#258017; text-decoration:line-through;}
.lastUp strong{color:#258017; font-weight:700;}
.lastUp em{font-style:normal; padding:0 10px;}
a.viewDiscountN{background-color:#258017; font-family:'Roboto', sans-serif; font-weight:700; line-height:54px; text-transform:uppercase; font-size:25px; color:#fff; padding-left:24px; padding-right:24px; text-align:center; border-radius:3px; -webkit-border-radius:3px; float:right;}
a.viewDiscountN span{display:inline-block;} /* Formerly /images/whiteArrow.png */
a:hover.viewDiscountN{background-color:#3aad28;}
.reviewTitle{background-color:#222222; line-height:53px; padding-left:16px;}
.reviewTitle h1{font-family:'Roboto', sans-serif; font-weight:700; font-size:25px; letter-spacing:-0.025em; color:#fff; float:left;}
.sortBy{float:right; padding-top:8px; padding-right:9px;}
.sortBy label{float:left; font-size:16px; letter-spacing:-0.01em; color:#fff; line-height:36px; padding-right:9px;}
.sortBy select{float:left; background:#050505; border:1px solid #515050; border-radius:3px; -webkit-border-radius:3px; font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em; width:118px; height:36px; color:#fff; padding-left:8px;} /* Formerly /images/dateArrow.png */
.pagination{text-align:center; padding-top:26px; padding-bottom:38px;}
.pagination p{font-size:16px; color:#7d7c7c; letter-spacing:-0.01em; padding-bottom:15px;}
.pagination ul{font-size:0px;}
.pagination ul li{display:inline-block; vertical-align:top; font-size:18px; font-weight:500; margin:0 3px;}
.pagination ul li a{min-width:36px; line-height:34px; border:1px solid transparent; color:#fff; display:block; background-color:#222222; border-radius:3px; -webkit-border-radius:3px;}
.pagination ul li a.active, .pagination ul li a:hover{background-color:#ff055b;}
.pagination ul li.pagiPrev a{background:#fff; border:1px solid #b8b7b7; height:36px;} /* Formerly /images/pagiPrev.png */
.pagination ul li.pagiNext a{background:#fff; border:1px solid #b8b7b7; height:36px;} /* Formerly /images/pagiNext.png */
.pagination ul li.pagiPrev a:hover, .pagination ul li.pagiNext a:hover{background-color:#222; border-color:#222;}
.reviewBlock{border:2px solid #d8d8d8; padding:13px; display:flex; justify-content:space-between; margin-bottom:18px;}
.reviewBlock:last-child{margin-bottom:0px;}
.reviewPic{float:left; width:200px;}
.reviewInfo{float:right; width:calc(100% - 212px); display:flex; justify-content:space-between; flex-direction:column;}
.reviewInfo h2{font-family:'Roboto', sans-serif; font-weight:500; font-size:24px; letter-spacing:-0.025em;}
.reviewInfo h2 a{color:#302f2f;}
.reviewInfo h2 a:hover{color:#ff055b;}
.lastUpdate{font-family:'Roboto', sans-serif; font-weight:400; font-size:16px; color:#7d7c7c; border-bottom:1px solid #3d3b3b; padding-bottom:8px; letter-spacing:-0.01em;}
.lastUpdate br{display:none;}
.lastUpdate span{color:#258017; text-decoration:line-through;}
.lastUpdate strong{color:#258017; font-weight:700;}
.lastUpdate em{font-style:normal; padding:0 10px;}
.reviewContent{padding-bottom:10px;}
.reviewContent p{font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em; line-height:19px; color:#232323;}
a.readReview{font-size:16px; color:#ff055b; margin-top:2px; display:inline-block; float:right;}
a.readReview span{text-decoration:underline;}
a:hover.readReview span{text-decoration:none;}
a.reviewVDiscount{background-color:#258017; font-family:'Roboto', sans-serif; font-weight:700; line-height:54px; text-transform:uppercase; font-size:25px; color:#fff; text-align:center; border-radius:3px; -webkit-border-radius:3px; float:right; padding:0 38px;}
a.reviewVDiscount span{display:inline-block;} /* Formerly /images/whiteArrow.png */
a:hover.reviewVDiscount{background-color:#3aad28;}
a.reviewVSite{background-color:#222222; font-family:'Roboto', sans-serif; font-weight:700; line-height:54px; text-transform:uppercase; font-size:25px; color:#fff; text-align:center; border-radius:3px; -webkit-border-radius:3px; float:right; padding:0 38px;}
a.reviewVSite span{display:inline-block;} /* Formerly /images/pinkArrow.png */
a:hover.reviewVSite{background-color:#444;}
.nicheArea,.filterArea{padding-bottom:80px;}

/* Review */
.SiteTitle{background-color:#222222; line-height:53px; margin-bottom:10px;}
.SiteTitle h1{float:left; font-size:25px; font-weight:700; color:#ffffff; letter-spacing:-0.025em; padding-left:17px;}
.SiteTitle p{font-size:16px; color:#fff; padding-right:18px; float:right;}
.SiteTitle p span{padding:0 8px;}
.fSiteDetailsN h1{font-size:16px; font-weight:700; color:#222222; border-bottom:1px solid #3d3b3b; padding-bottom:4px;}
.reviewCon{padding-bottom:5px;}
.reviewCon p{font-family:'Roboto', sans-serif; font-weight:400; font-size:15px; letter-spacing:-0.01em; line-height:21px; color:#7d7c7c;}
.lessSpace{margin-bottom:18px;}
.contentArea{margin-bottom:18px;}
.contentTitle{background-color:#222222; margin-bottom:10px;}
.contentTitle h1{font-weight:700; font-size:25px; letter-spacing:-0.025em; color:#fff; line-height:53px; padding-left:18px; float:left;}
.contentRate{float:right; margin-top:7px; margin-right:7px; background-color:#ff055b; line-height:39px; padding:0 10px; font-size:20px; font-weight:500; color:#fff; letter-spacing:-0.01em; min-width:82px; text-align:center;}
.contentBlock{background-color:#f1f1f1; padding:30px 28px;}
.exclusive{ padding-bottom:18px;}
.exclusive li{float:left; width:15.83333333333333%; line-height:32px; text-align:center; margin-right:1%; font-size:15px; color:#222222; letter-spacing:-0.01em; background-color:#fff;}
.exclusive li:last-child{margin-right:0px;}
.exclusive li span{color:#ff055b; font-weight:bold;}
.contentBlock p{font-size:16px; line-height:21px; color:#868686; letter-spacing:-0.01em; padding-bottom:21px; font-weight:400;}
.contentBlock p:last-child{padding-bottom:0px;}
.dnf{padding-bottom:18px;}
.dnf li{float:left; width:198px; line-height:32px; text-align:center; margin-right:10px; font-size:15px; color:#222222; letter-spacing:-0.01em; background-color:#fff;}
.dnf li:last-child{margin-right:0px;}
.dnf li span{color:#ff055b; font-weight:bold;}
.clickHere{text-align:center; padding-top: 22px; padding-bottom: 22px;}
.clickHere a{background-color:#ff055b; font-family:'Roboto', sans-serif; font-weight:700; line-height:58px; text-transform:uppercase; font-size:25px; color:#fff; text-align:center; border-radius:3px; -webkit-border-radius:3px; padding:0 40px; display:inline-block}
.clickHere a span{display:inline-block;} /* Formerly /images/whiteArrow.png */
.clickHere a:hover{background-color:#222222;}
.memberArea{background-color:#f1f1f1; padding:25px 28px;}
.screenshot{float:left; margin-right:11px;}
.screenshot:nth-child(4n){margin-right:0px;}
.prosCons ul{float:left; width:49.5%; margin-right:1%;}
.prosCons ul:last-child{margin-right:0px;}
.prosCons ul li{line-height:32px; background-color:#fff; color:#222222; line-height:32px; margin-bottom:8px; padding:0 15px; font-size:15px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
.prosCons ul li:last-child{margin-bottom:0px;}
.pros span{font-weight:900; color:#ff055b;}
.cons span{font-weight:900; color:#051dff;}
.comment{padding:14px 18px; color:#868686; font-size:15px; line-height:21px; letter-spacing:-0.01em; margin-bottom:20px; background-color:#ffffff;}
.textareaBox{background-color:#fff; border:none; font-family:'Roboto', sans-serif; font-size:15px; line-height:21px; font-weight:400; color:#868686; width:100%; height:110px; padding:14px 18px; resize:none; margin-bottom:20px;}
.sComment{font-family:'Roboto', sans-serif; font-size:15px; height:42px; border-radius:3px; -webkit-border-radius:3px; border:none; cursor:pointer; float:right; color:#fff; padding-right:36px; padding-left:16px; text-transform:uppercase; background-size:14px; font-weight:700;} /* Formerly /images/pinkArrow.png */
.sComment:hover{background-color:#444;}
.commentArea{margin-bottom:80px;}


.topLinks{background-color:#ff055b; line-height:58px; text-align:center; display:none;}
.topLinks a{color:#fff; font-family:'Roboto', sans-serif; font-weight:700; font-size:24px; text-transform:uppercase; display:block;}
.topLinks a span{display:inline-block;} /* Formerly /images/whiteArrow.png */
.topLinks a:hover{background-color:#222222;}
.sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:btneffect; animation-duration:500ms;}
@keyframes btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#ff055b;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#ff055b;
 }
}

/* Comments */

.commentFormBlock p{color:#000;}
.comments-form {padding:5px;}
.comments-form .form-group {padding-bottom:10px;}
.comment-date {color:#868686;}
.comment-item {margin-bottom:10px;}
.comment-item h4{background-color:#222;color:#fff;line-height:30px;padding-left:5px;}
.comment-text {background-color:#f0f0f0;border:1px solid #888;}
.comment-text p{background-color:#fff;color:#000;padding:10px 5px 10px 5px; padding-bottom:10px !important;}


/* Themes */
#straight .clickHere a{background-color:#FF055B;}
#straight .contentRate,#straight .rating,#straight .ratingN,#straight .siteRating{background-color:#FF055B;}
#straight .dnf li span,#straight .exclusive li span{color:#FF055B;}
#straight .fSiteTitle h2 span,#straight .fSiteTitleN h2 span{color:#FF055B;}
#straight .fullReview a:hover{color:#FF055B;}
#straight .pagination ul li a.active, .pagination ul li a:hover{background-color:#FF055B;}
#straight .siteName a{color:#FF055B;}
#straight .sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:straight-btneffect; animation-duration:500ms;}
#straight .topLinks{background-color:#FF055B;}
#straight .topSites a span{color:#FF055B;}
#straight aside h1{background-color:#FF055B;}
#straight a.review,#straight a.readReview{color:#FF055B;}
@keyframes straight-btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#ff055b;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#ff055b;
 }
}


#gay .clickHere a{background-color:#055BFF;}
#gay .contentRate,#gay .rating,#gay .ratingN,#gay .siteRating{background-color:#055BFF;}
#gay .dnf li span,#gay .exclusive li span{color:#055BFF;}
#gay .fSiteTitle h2 span,#gay .fSiteTitleN h2 span{color:#055BFF;}
#gay .fullReview a:hover{color:#055BFF;}
#gay .pagination ul li a.active, .pagination ul li a:hover{background-color:#055BFF;}
#gay .siteName a{color:#055BFF;}
#gay .sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:gay-btneffect; animation-duration:500ms;}
#gay .topLinks{background-color:#055BFF;}
#gay .topSites a span{color:#055BFF;}
#gay aside h1{background-color:#055BFF;}
#gay a.review,#gay a.readReview{color:#055BFF;}
@keyframes gay-btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#055BFF;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#055BFF;
 }
}


#dating .clickHere a{background-color:#CE0BAC;}
#dating .contentRate,#dating .rating,#dating .rating,#dating .siteRating{background-color:#CE0BAC;}
#dating .dnf li span,#dating .exclusive li span{color:#CE0BAC;}
#dating .fSiteTitle h2 span,#dating .fSiteTitleN h2 span{color:#CE0BAC;}
#dating .fullReview a:hover{color:#CEOBAC;}
#dating .pagination ul li a.active, .pagination ul li a:hover{background-color:#CE0BAC;}
#dating .siteName a{color:#CE0BAC;}
#dating .sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:dating-btneffect; animation-duration:500ms;}
#dating .topLinks{background-color:#CE0BAC;}
#dating .topSites a span{color:#CE0BAC;}
#dating aside h1{background-color:#CE0BAC;}
#dating a.review,#dating a.readReview{color:#CE0BAC;}
@keyframes dating-btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#CE0BAC;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#CE0BAC;
 }
}


#cams .clickHere a{background-color:#258017;}
#cams .contentRate,#cams .rating,#cams .rating,#cams .siteRating{background-color:#258017;}
#cams .dnf li span,#cams .exclusive li span{color:#258017;}
#cams .fSiteTitle h2 span,#cams .fSiteTitleN h2 span{color:#258017;}
#cams .fullReview a:hover{color:#258017;}
#cams .pagination ul li a.active, .pagination ul li a:hover{background-color:#258017;}
#cams .siteName a{color:#258017;}
#cams .sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:cams-btneffect; animation-duration:500ms;}
#cams .topLinks{background-color:#258017;}
#cams .topSites a span{color:#258017;}
#cams aside h1{background-color:#258017;}
#cams a.review,#cams a.readReview{color:#258017;}
@keyframes cams-btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#258017;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#258017;
 }
}


#adult .clickHere a{background-color:#FF8900;}
#adult .contentRate,#adult .rating, #adult .siteRating{background-color:#FF8900;}
#adult .dnf li span,#adult .exclusive li span{color:#FF8900;}
#adult .fSiteTitle h2 span,#adult .fSiteTitleN h2 span{color:#FF8900;}
#adult .fullReview a:hover{color:#FF8900;}
#adult .pagination ul li a.active, .pagination ul li a:hover{background-color:#FF8900;}
#adult .siteName a{color:#FF8900;}
#adult .sticky{display:block; left:0px!important; width:100%!important; z-index:11; animation-name:adult-btneffect; animation-duration:500ms;}
#adult .topLinks{background-color:#FF8900;}
#adult .topSites a span{color:#FF8900;}
#adult aside h1{background-color:#FF8900;}
#adult a.review,#adult a.readReview{color:#FF8900;}
@keyframes adult-btneffect {
 0% {
  top:-100px;
  background-color:rgba(255,255,255,0)
 }
 60% {
  top:0;
  line-height:72px;
  height:72px;
  background-color:#FF8900;
 }
 100% {
  top:0;
  line-height:58px;
  height:58px;
  background-color:#FF8900;
 }
}


/*
#straight .sidebox3 .fea .ftitle,#straight .sidebox3 .fea .rating,#straight .sidebox2 .fea .rating,#straight .searchhl,#straight .reso .b1-2,#straight .ratingpercent,#straight .specialstable .title{background:#FF0058;}
#straight .specialstable .discountp,#straight .content .console .ranking strong,#straight .sidebox1 h4,#straight a:hover,#straight .sidebox2 .topmenu li a .right,#straight .sidebox2 h4,#straight .content .intro h2,#straight .reso .w1-a a,#straight .reso .w3-1 a,#straight .revi h3,#straight .revi h3 a,#straight .revi .block1 h1 a,#straight .revi .block1 h1,#straight .revi .block1 .txt a,#straight .revi .block1 .txt2,#straight .revi .block1 .h4color,#straight .revi .block2 .h1color,#straight .content h2.title{color:#FF0058;}
#straight .ratingbox,#straight .revi .total,#straight .specialstable .specialalt{background:#FFB7D0;}
#straight .reso .lighttxt{color:#FFB7D0;}
#straight .content .console .ranking{border:1px solid #FFB7D0;}
#straight .specialstable .special{background:#FEDEE9;}

#gay .sidebox3 .fea .ftitle,#gay .sidebox3 .fea .rating,#gay .sidebox2 .fea .rating,#gay .searchhl,#gay .reso .b1-2,#gay .ratingpercent,#gay .specialstable .title{background:#00AEFF;}
#gay .specialstable .discountp,#gay .content .console .ranking strong,#gay .sidebox1 h4,#gay a:hover,#gay .sidebox2 .topmenu li a .right,#gay .sidebox2 h4,#gay .content .intro h2,#gay .reso .w1-a a,#gay .reso .w3-1 a,#gay .revi h3,#gay .revi h3 a,#gay .revi .block1 h1 a,#gay .revi .block1 h1,#gay .revi .block1 .txt a,#gay .revi .block1 .txt2,#gay .revi .block1 .h4color,#gay .revi .block2 .h1color,#gay .content h2.title{color:#00AEFF;}
#gay .ratingbox,#gay .revi .total,#gay .specialstable .specialalt{background:#B0E6FF;}
#gay .reso .lighttxt{color:#B0E6FF;}
#gay .content .console .ranking{border:1px solid #B0E6FF;}
#gay .specialstable .special{background:#D5F2FF;}

#dating .sidebox3 .fea .ftitle,#dating .sidebox3 .fea .rating,#dating .sidebox2 .fea .rating,#dating .searchhl,#dating .reso .b1-2,#dating .ratingpercent,#dating .specialstable .title{background:#2CBA00;}
#dating .specialstable .discountp,#dating .content .console .ranking strong,#dating .sidebox1 h4,#dating a:hover,#dating .sidebox2 .topmenu li a .right,#dating .sidebox2 h4,#dating .content .intro h2,#dating .reso .w1-a a,#dating .reso .w3-1 a,#dating .revi h3,#dating .revi h3 a,#dating .revi .block1 h1 a,#dating .revi .block1 h1,#dating .revi .block1 .txt a,#dating .revi .block1 .txt2,#dating .revi .block1 .h4color,#dating .revi .block2 .h1color,#dating .content h2.title{color:#2CBA00;}
#dating .ratingbox,#dating .revi .total,#dating .specialstable .specialalt{background:#C2F7B1;}
#dating .reso .lighttxt{color:#C2F7B1;}
#dating .content .console .ranking{border:1px solid #C2F7B1;}
#dating .specialstable .special{background:#E0FCD7;}

#cams .sidebox3 .fea .ftitle,#cams .sidebox3 .fea .rating,#cams .sidebox2 .fea .rating,#cams .searchhl,#cams .reso .b1-2,#cams .ratingpercent,#cams .specialstable .title{background:#FF00D8;}
#cams .specialstable .discountp,#cams .content .console .ranking strong,#cams .sidebox1 h4,#cams a:hover,#cams .sidebox2 .topmenu li a .right,#cams .sidebox2 h4,#cams .content .intro h2,#cams .reso .w1-a a,#cams .reso .w3-1 a,#cams .revi h3,#cams .revi h3 a,#cams .revi .block1 h1 a,#cams .revi .block1 h1,#cams .revi .block1 .txt a,#cams .revi .block1 .txt2,#cams .revi .block1 .h4color,#cams .revi .block2 .h1color,#cams .content h2.title{color:#FF00D8;}
#cams .ratingbox,#cams .revi .total,#cams .specialstable .specialalt{background:#FFBAF4;}
#cams .reso .lighttxt{color:#FFBAF4;}
#cams .content .console .ranking{border:1px solid #FFBAF;}
#cams .specialstable .special{background:#FFDCF9;}

#adult .sidebox3 .fea .ftitle,#adult .sidebox3 .fea .rating,#adult .sidebox2 .fea .rating,#adult .reso .b1-2,#adult .ratingpercent,#adult .searchhl,#adult .specialstable .title{background:#FF8E0A;}
#adult .specialstable .discountp,#adult .content .console .ranking strong,#adult .sidebox1 h4,#adult a:hover,#adult .sidebox2 .topmenu li a .right,#adult .sidebox2 h4,#adult .content .intro h2,#adult .reso .w1-a a,#adult .reso .w3-1 a,#adult .revi h3,#adult .revi h3 a,#adult .revi .block1 h1 a,#adult .revi .block1 h1,#adult .revi .block1 .txt a,#adult .revi .block1 .txt2,#adult .revi .block1 .h4color,#adult .revi .block2 .h1color,#adult .content h2.title{color:#FF8E0A;}
#adult .ratingbox,#adult .revi .total,#adult .specialstable .specialalt{background:#FFDBB1;}
#adult .reso .lighttxt{color:#FFDBB1;}
#adult .content .console .ranking{border:1px solid #FFDBB1;}
#adult .specialstable .special{background:#FFECD6;}
*/
/* Top menu styling for large screens. */
@media only screen and (min-width: 1024px) {
    /* Hide the hamburger */
    .menu-toggle,
    .main-menu .menu-close,
    .main-menu:target .menu-close,
    .main-menu[aria-expanded="true"] .menu-close {
        display: none;
    }

    /* Undo positioning of off-canvas menu */
    .main-menu {
        display: block;
        position: relative;
        left: auto;
        top: auto;
        height: auto;
    }

    .main-menu ul {
        /* Allow for full height of menu */
        display: flex;

        /* Undo off-canvas styling */
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        background: none;
        box-shadow: none;
        padding: 0;
        height: auto;
        width: auto;
    }

    .main-menu a {
        border: 0 !important; /* Remove borders from off-canvas styling */
        padding-left: 0px;
        padding-right: 0px;
    }

    .main-menu a:hover,
    .main-menu a:focus {
        background: none; /* Remove background from off-canvas styling */
    }
}


@media only screen and (max-width:1279px){
.centerwrap{width:994px;}
.logo{width:200px; margin:5px 0;}
nav li{font-size:13px; margin-right:16px;}
nav{margin-right:20px;}
.searchArea{width:160px;padding-right:8px;}
.bookmark{margin-left:10px;}
.fSiteTitle h2{font-size:22px;}
.rating{font-size:18px;}
.fSiteTitle{line-height:42px;}
.trmc{width:315px;}
.trmc li{width:155px!important; line-height:26px; font-size:13px;}
.discount{width:154px; font-size:18px; line-height:24px;}
.discount span{font-size:30px; line-height:30px;}
a.viewDiscountF{padding:0 24px; line-height:54px; font-size:18px;}
a.visitSite{padding:0 24px; line-height:56px; font-size:23px;}
.prevSlide{left:10px;}
.nextSlide{right:10px;}
.bodyRight{width:calc(100% - 175px);}
.siteReview{width:49%; margin-right:2%; margin-bottom:2%; padding:10px 8px;}
.siteReviewPic{width:40%;}
.siteReviewD{width:58%;height:220px;}
.siteReviewD h2{font-size:20px;}
.lastUpdates{font-size:14px; line-height:18px; padding-bottom:5px;}
.siteReviewC{padding-top:4px;}
.siteReviewC p{font-size:15px; line-height:20px; max-height:100px;}
a.visitSiteR{width:58%; font-size:20px; line-height:42px;}
a.visitSiteR i{transform:scale(0.75);}
a.visitSiteR span{padding-right:24px;}
.siteRating{line-height:42px; font-size:18px;}
a.viewDiscount{width:58%; font-size:20px; line-height:42px;}
a.viewDiscount i{transform:scale(0.75);}
a.viewDiscount span{padding-right:22px;}
h3.title{font-size:22px; line-height:50px;}
.rateDis{padding-top:12px;}
.discountPorn {padding-bottom:12px;}
.discountPorn .pornTitle {padding-left:12px; padding-top:12px;}
.discountPorn .content {padding-left:12px;}
.discountPorn div {font-size:13px;}
.reviewsSoon a{width:31%; margin-right:1%; margin-bottom:1%; font-size:13px;}
.reviewsSoon a:nth-child(4n+1){margin-right:1%;}
.reviewsSoon a:nth-child(3n+1){margin-right:0px;}
.reviewsSoon{margin-bottom:50px;}
.topLinks a{font-size:22px;}


/* Niche */
.innerBody{padding-top:80px;}
.fSiteTitleN{line-height:40px;}
.fSiteTitleN h2{font-size:20px;}
.lastUp{font-size:15px; padding-top:3px;}
.fSiteContentN{padding-top:5px;}
.fSiteContentN p{font-size:14px; line-height:20px;}
a.reviewN{font-size:14px;}
.ratingN{font-size:18px;}
.discountN{margin-left:20px; font-size:18px; line-height:20px;}
.discountN span{font-size:30px; line-height:28px;}
.lastUpdate{font-size:15px;}
.reviewInfo h2{font-size:22px;}
.reviewContent{margin:0px;max-height:127px;}
.reviewContent p{font-size:14px; line-height:18px; max-height:90px; overflow:hidden;}
a.readReview{font-size:14px;}

/* Review */
.reviewCon p{font-size:14px; line-height:18px;}
.SiteTitle{line-height:50px;}
.SiteTitle h1{font-size:22px;}
.contentTitle h1{font-size:22px;}
.SiteTitle p{font-size:15px;}
.contentBlock{padding:25px 23px;}
.exclusive{padding-bottom:12px;}
.exclusive li{font-size:14px;}
.contentBlock p{font-size:15px; padding-bottom:18px;}
.dnf{padding-bottom:12px;}
.dnf li{font-size:14px; width:24.25%; margin-right:1%;}
.dnf li:nth-child(4n){margin-right:0px;}
.memberArea{padding:22px 23px;}
.screenshot{width:24.25%; margin-right:1%;}
.prosCons ul li{font-size:14px;}
.comment{font-size:14px;}






}
@media only screen and (max-width:1023px){
.centerwrap{width:748px;}
.hRight{margin-right:60px;}
nav li a:after{display:none;}
.searchArea{width:180px;}
.customNavigation{height:28px;}
.nextSlide,.prevSlide{transform:scale(0.75);}
.bookmark{z-index:10; position:relative;}
.searchBlock{z-index:10; position:relative;}
.fSiteDetails{width:calc(100% - 190px);}
.fSiteTitle h2{font-size:18px; padding-left:12px;}
.fSiteTitle{line-height:36px;}
.rating{font-size:15px; width:70px;}
.fSiteContent p{font-size:14px; line-height:20px;}
a.review{font-size:14px;}
.item{display:block;}
.fSiteInfo{display:block;}
.trmc{width:100%; padding-bottom:10px;}
.trmc li{width:49%!important; margin-right:2%; margin-bottom:2%; font-size:14px; line-height:28px;}
a.viewDiscountF{float:right;}
a.visitSite{float:right;}
.discount{width:auto;}
h3.title{font-size:22px; line-height:44px; margin-bottom:10px;}
.siteReviewPic{width:35%;}
.siteReviewD{width:62%;height:180px;}
.siteReviewD h2{font-size:18px;}
.lastUpdates{font-size:13px; line-height:16px;}
.siteReviewC p{font-size:15px; line-height:20px; max-height:100px;}
.fullReview{font-size:13px;}
.rateDis:before,.rateDis:after{content:" "; display:table;}
.rateDis:after{clear:both;}
.rateDis{clear:both; display:block; padding-top:8px; *zoom:1;}
.siteRating{font-size:15px; line-height:35px; float:left;}
.siteDiscount{width:auto; font-size:12px; line-height:12px; padding-top:0px; float:right;}
.siteDiscount span{font-size:18px; line-height:20px;}
a.viewDiscount{width:100%; float:left; clear:both; margin-top:10px; line-height:42px; font-size:20px;}
a.visitSiteR{width:100%; float:left; clear:both; margin-top:10px; line-height:42px; font-size:20px;}
.siteReview{padding:8px;}
.viewMore a{font-size:20px; line-height:52px;}
.viewMore{padding-top:16px; padding-bottom:28px;}
.discountPorn .pornTitle div{line-height:16px;}
.discountPorn .content .siteName, .discountPorn .pornTitle .siteName {padding:0 10px;}
.discountPorn .content .niche, .discountPorn .pornTitle .siteName {padding:0 10px;}
.reviewsSoon a{width:48%;}
.reviewsSoon a:nth-child(3n+1){margin-right:1%;}
.reviewsSoon a:nth-child(2n+1){margin-right:0px;}
.fLogo{float:none; margin:auto; width:230px;}
.footerRight{float:none; padding-top:0px; padding-bottom:15px;}
.social{float:none; text-align:center; padding-bottom:10px; font-size:0px;}
.social a{float:none; display:inline-block; margin:0 4px;}
.flinks{float:none; margin-right:0px; text-align:center;}
.flinks p{text-align:center;}
.topRated a{margin:0 10px;}
.topRated a:last-child{margin-right:10px;}




/* Niche */
.innerBody{padding-top:75px;}
.featuredSite{padding:10px;}
.fSitePicN{float:left; width:25%;}
.featuredSite{display:block;}
.featuredSite:before,.featuredSite:after{content:" "; display:table;}
.featuredSite:after{clear:both;}
.featuredSite{*zoom:1; margin-bottom:18px;}
.fSiteDetailsN{width:calc(100% - 27%);}
.fSiteTitleN{line-height:20px; padding:8px 10px;}
.fSiteTitleN h2{font-size:18px;}
.ratingN{font-size:16px; line-height:44px; width:75px;}
.discountN{margin-left:8px; font-size:15px; line-height:18px;}
.discountN span{font-size:24px; line-height:22px;}
a.viewDiscountN{font-size:20px; line-height:44px; padding-left:15px; padding-right:15px;}
a.viewDiscountN i{transform:scale(0.85);}
.fSiteContentN{padding-bottom:6px;}
.reviewTitle h3{font-size:22px;}
.reviewTitle{padding-left:14px; line-height:50px;}
.sortBy select{height:32px; font-size:14px;}
.sortBy label{line-height:32px; font-size:15px;}
.sortBy{padding-top:9px;}
.lastUp em, .lastUpdate em{padding:0 6px; display:none;}
.pagination p{padding-bottom:12px; font-size:15px;}
.pagination{padding-top:16px; padding-bottom:24px;}
.pagination ul li a{min-width:32px; line-height:30px;}
.pagination ul li.pagiPrev a, .pagination ul li.pagiNext a{height:32px;}
.pagination ul li{margin:0 2px; font-size:15px;}
.reviewBlock:before,.reviewBlock:after{content:" "; display:table;}
.reviewBlock:after{clear:both;}
.reviewBlock{padding:8px; display:block; *zoom:1; margin-bottom:10px;}
.reviewPic{width:25%;}
.reviewInfo{width:calc(100% - 27%); display:block;}
.lastUpdate{margin:0px;}
.reviewContent{padding-top:8px; max-height:133px;}
.reviewContent p{font-size:15px;line-height:19px;max-height:95px;}
a.reviewVDiscount{font-size:20px; line-height:44px; padding-left:15px; padding-right:15px;}
a.reviewVDiscount i{transform:scale(0.85);}
a.reviewVSite{font-size:20px; line-height:44px; padding-left:15px; padding-right:15px;}
a.reviewVSite i{transform:scale(0.85);}
.lastUp br, .lastUpdate br{display:block;}
.nicheArea,.filterArea{padding-bottom:50px;}

/* Review */
.reviewCon{padding-top:5px;}
.exclusive{padding-bottom:5px;}
.exclusive li{width:32%; margin-right:2%; margin-bottom:2%;}
.exclusive li:nth-child(3n){margin-right:0px;}
.contentBlock{padding:15px 13px;}
.contentBlock p{font-size:14px; line-height:20px; padding-bottom:15px;}
.SiteTitle p{font-size:14px; padding-right:12px;}
.dnf li{width:32%; margin-right:2%; margin-bottom:2%;}
.dnf li:nth-child(3n){margin-right:0px;}
.dnf{padding-bottom:5px;}
.clickHere a{font-size:20px; line-height:52px;}
.clickHere{padding-top:15px; padding-bottom:12px;}
.memberArea{padding:15px 13px;}
.prosCons ul li{margin-bottom:5px;}
.comment{padding:10px 14px; line-height:20px; margin-bottom:14px;}
.textareaBox{font-size:14px; padding:10px 14px; line-height:20px; margin-bottom:14px;}
.commentArea{margin-bottom:60px;}
}
@media only screen and (max-width:767px){
.centerwrap{width:96%;}
.bookmark a{width:21px; height:21px; padding-left:0px;}
.bookmark a span{display:none;}
.hRight{margin-right:50px;}
.bookmark{margin-left:2px;}
.fSitePic{padding:5px;}
.fSiteDetails{width:calc(100% - 150px);}
.fSiteTitle{line-height:32px;}
.fSiteTitle h2{font-size:15px; padding-left:8px;}
.rating{font-size:13px; width:60px;}
.trmc li{font-size:13px;}
.discount{font-size:15px; line-height:20px;}
.discount span{font-size:26px; line-height:26px;}
a.viewDiscountF{padding:0 20px; line-height:48px; font-size:15px;}
a.visitSite{padding:0 20px; line-height:50px; font-size:22px;}
aside{width:100%;}
.topSites a, .topniches a {display:none;}
.topSites, .topniches{margin-bottom:10px;}
aside h1{position:relative; cursor:pointer;}
aside h1:after{background:url(/static/images/glyphs.png) -73px -43px; width:16px; height:10px; position:absolute; right:10px; top:10px; content:'';}
aside h1.active:after{transform:rotate(180deg); top:9px;}
.bodyRight{width:100%;}
.siteReview{width:100%; margin-right:0px;}
.siteReviewPic{width:30%;}
.siteReviewD{width:68%;height:100%;}
.siteReviewD h2{font-size:20px;}
.lastUpdates{font-size:15px; line-height:20px;}
.siteReviewC p{font-size:15px; line-height:20px; max-height:100px;}
.fullReview{font-size:15px;}
.rateDis{display:flex;}
.rateDis:before, .rateDis:after{display:none;}
a.visitSiteR{width:40%; margin-top:0px; line-height:35px; font-size:19px;}
a.viewDiscount{width:40%; margin-top:0px; line-height:35px; font-size:19px;}
.discountPorn {padding-bottom:8px;}
.discountPorn .pornTitle {padding-left:8px; padding-top:8px;}
.discountPorn .content {padding-left:8px;}
.siteName{width:23vw;}
.dPrice{width:23vw;}
.rPrice{width:23vw;}
.siteRatingB{width:23vw;}
.reviewsSoon{margin-bottom:30px;}

.topLinks a span{padding-right:0px; background:none;}

/* Niche */
.fSiteTitleN h2 span{display:block;}
a.viewDiscountN{font-size:18px; padding-left:12px;}
a.reviewVDiscount{font-size:18px; padding-left:12px;}
a.reviewVSite{font-size:18px; padding-left:12px;}
.fSiteInfoN, .reviewPDiscountInfo{clear:both;}
.reviewPDiscountInfo{padding-top:10px;}
.reviewBlock{margin-bottom:12px;}
.nicheArea,.filterArea{padding-bottom:40px;}

/* Review */
.SiteTitle p{line-height:normal; float:left; clear:both; padding-left:17px;}
.SiteTitle h1{line-height:normal; padding-bottom:4px;}
.SiteTitle{padding:8px 0 12px; margin-bottom:6px;}
.contentTitle h1{line-height:44px; font-size:20px; padding-left:13px;}
.contentRate{font-size:18px; line-height:30px;}
.prosCons ul li{padding:0 12px;}



}
@media only screen and (max-width:567px){
.fSitePic{float:none; margin:auto; margin-bottom:15px;}
.fSiteDetails{width:100%;}
.fSiteContent{padding-top:8px;}
.customNavigation{top:21%;}
h3.title{font-size:19px;}
a.visitSiteR, a.viewDiscount{width:50%;}
.viewMore a{display:block; padding:0 15px; line-height:46px;}
.viewMore a span{padding-right:34px;}
/*.discountPorn {overflow:auto;}*/
/*.discountPorn div div {width:100%;}*/
.discountPorn {padding-bottom:4px;}
.discountPorn .pornTitle {padding-left:4px; padding-top:4px;}
.discountPorn .content {padding-left:4px;}
.siteReviewD{height:100%;}
.siteReviewC p{font-size:14px; line-height:19px; max-height:95px;}
.social a{width:32px; height:32px;}
.social i{transform:scale(0.85);}
#glyph-rss,#glyph-twitter {margin:6px;}


.topLinks a{font-size:20px;}

/* Niche */
a.viewDiscountN{clear:both; margin-top:8px; width:100%;}
a.reviewVDiscount{clear:both; margin-top:8px; width:100%;}
a.reviewVSite{clear:both; margin-top:8px; width:100%;}

/* Review */
.SiteTitle h1{font-size:19px; padding-left:13px;}
.SiteTitle p{padding-left:13px; font-size:13px;}
.contentTitle h1{font-size:19px;}
.contentBlock{padding:12px 10px;}
.clickHere a{display:block; padding:0 15px; line-height:46px;}
.clickHere{padding-top:5px; padding-bottom:10px;}
.screenshot{width:49%; margin-right:2%; margin-bottom:2%;}
.screenshot:nth-child(2n){margin-right:0px;}
.prosCons ul{width:100%; margin-right:0px;}
.pros{margin-bottom:15px;}


}
@media only screen and (max-width:479px){
.centerwrap{width:94%;}
.logo{width:170px; margin:8px 0;}
.featureSlider{margin:10px 0; padding:14px 26px;}
.fSiteTitle h2 span{display:block;}
.customNavigation{top:16%;}
.trmc li{width:100%!important; margin-right:0px;}
.trmc li:nth-child(3){margin-bottom:2%;}
.discount{width:100%; margin-bottom:10px;}
a.viewDiscountF{width:100%; text-align:center;}
a.viewDiscountF span{display:inline-block;}
a.visitSite{width:100%; text-align:center;}
a.visitSite span{display:inline-block;}
a.visitSiteR, a.viewDiscount{width:100%; margin-top:8px;}
.rateDis{display:block;}
.lastUpdates{font-size:13px; line-height:18px;}
.siteReviewC p{font-size:15px; line-height:20px; max-height:100px;}
.fullReview{font-size:13px;}
.siteReviewPic{width:38%;}
.siteReviewD{width:58%;height:100%;}
.viewMore a span{background:none; padding-right:0px;}
.viewMore a{font-size:18px; line-height:22px; padding:10px 15px;}
.viewMore{padding-bottom:24px;}
/*.discountPorn div {width:150%;}*/
h3.title{font-size:18px;}
.reviewsSoon a {font-size:13px; padding:0 8px;}

.featureSlider{margin-top:70px;}
.innerBody{padding-top:70px;}
.topLinks{line-height:22px; visibility:hidden;}
.topLinks a{font-size:18px; padding:7px;}
.sticky{animation-name:none; visibility:visible;}


/* Niche */
.fSitePicN{width:max-content; float:none; margin:auto; margin-bottom:10px;}
.fSiteDetailsN{width:100%; }
.fSiteTitleN h2{font-size:16px;}
.reviewTitle h3{font-size:18px; padding-top:5px;}
.reviewTitle{line-height:normal; padding-left:10px;}
.sortBy{padding-top:5px; padding-bottom:10px; float:left; clear:both;}
.pagination ul li{margin:0 2px 4px;}
.reviewPic{width:35%;}
.reviewInfo{width:calc(100% - 38%);}
.reviewInfo h2{font-size:20px;}
.lastUpdate{font-size:13px; line-height:18px;}
.reviewContent{max-height:119px;}
.reviewContent p{font-size:13px; line-height:17px; max-height:85px;}
a.readReview{font-size:13px;}
.ratingN{font-size:14px; line-height:32px; width:100%;}
.discountN{width:100%; padding-top:6px;}
a.viewDiscountN{font-size:16px;}
a.viewDiscountN i{transform:scale(0.80);}
a.reviewVDiscount{font-size:16px;}
a.reviewVDiscount i{transform:scale(0.80);}
a.reviewVSite{font-size:16px;}
a.reviewVSite i{transform:scale(0.80);}
.nicheArea,.filterArea{padding-bottom:20px;}

/* Review */
.reviewCon{padding-bottom:10px;}
.exclusive li{width:49%;}
.exclusive li:nth-child(3n){margin-right:2%;}
.exclusive li:nth-child(2n){margin-right:0px;}
.contentRate{font-size:16px; min-width:70px}
.dnf li{width:100%; margin-right:0px;}
.clickHere a{font-size:18px; line-height:22px; padding:10px 15px;}
.clickHere a span{background:none; padding-right:0px;}
.contentTitle h1{font-size:18px;}
.commentArea{margin-bottom:40px;}
}

/* Hide the search bar when the screen is too small to show it */
@media only screen and (max-width:400px){
.searchBlock{position:relative;}
.searchArea{position:fixed; right:1%; z-index:1; width:98%; display:none; top:57px;}
}
#comment-waiting {
  line-height: 16px;
}

#comment-waiting img {
  vertical-align: middle;
  padding: 0 4px 0 10px;
}

#comment-added-message,
#comment-thanks {
  padding-left: 10px;
}

.comment-moderated-flag {
  font-variant: small-caps;
  margin-left: 5px;
}

#div_id_honeypot {
  /* Hide the honeypot from django_comments by default */
  display: none;
}


/* ---- threaded comments ---- */

ul.comment-list-wrapper {
  /* to avoid touching our own "ul" tags, our tags are explicitly decorated with a class selector */
  margin-left: 0;
  padding-left: 0;
}

ul.comment-list-wrapper ul.comment-list-wrapper {
  margin-left: 1em;
  padding-left: 0;
}

li.comment-wrapper {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.js-comments-form-orig-position .comment-cancel-reply-link {
  display: none;
}
